<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">
    <p class="my-4 custom-title">Manage Discount</p>
    <div class="card-main-box">
      <div class="discount-card" v-for="item in discountDetails" :key="item.id">
        <div class="tag_wrap">
          <div class="discount price">{{ item.is_fixed ? `$${item.amount_off}` : `${item.percent_off}%` }}</div>
          <div class="text-right">
            <div class="coupon-date">{{ formatDate(item.start_at) }} - {{ formatDate(item.valid_at) }}</div>
            <div class="coupon-code">{{ item.code }}</div>
            <span :class="['tag',!item.is_active ? 'disable' : '' ]">{{ item.is_active ? `Active` : `Disabled` }}</span>
          </div>
        </div>
        <div class="card-footer">
          <div class="left">
            <v-btn
              plain
              :ripple="false"
              color="info"
              @click="openEditDialog(item.id)"
            >
              <v-icon size="17">{{ icons.mdiPencilOutline }}</v-icon>
              Edit
            </v-btn>
          </div>
          <div class="right">
            <v-btn
              plain
              :ripple="false"
              color="primary"
              @click="openDeleteDialog(item.id)"
            >
              <v-icon size="17">{{ icons.mdiTrashCanOutline }}</v-icon>
              Delete
            </v-btn>
          </div>
        </div>
      </div>

      <div class="add-price-box">
        <div class="card-body" style="margin-top: -20px">
          <button @click.prevent="openSaveDialog">
            <div class="inner-btn-div">
              <v-icon size="48">{{ icons.mdiPlusBoxMultipleOutline }}</v-icon>
              <span class="sub-title">ADD NEW DISCOUNT</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Save Discount Dialog -->
    <v-dialog v-model="saveDialog" persistent width="400">
      <v-card>
        <v-card-title class="text-h5"> Add Discount Detail </v-card-title>

        <v-card-text style="padding-top: 24px !important">
          <v-form validate-on="submit lazy" @submit.prevent="submit">
            <v-text-field outlined label="Discount Code" small v-model="discountObj.code" ></v-text-field>
            <v-text-field type="number" outlined label="Discount Value" v-model="discountObj.disValue"  ></v-text-field>
            <v-select label="Discount Type" outlined :items="['fixed', 'percentage']" v-model="discountObj.disType"  ></v-select>
            <v-menu ref="menu1" outlined v-model="menu1" :close-on-content-click="false"  transition="scale-transition"  offset-y max-width="290px"  min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="discountObj.startDate" label="Start From" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="discountObj.startDate" no-title @input="menu1 = false" ></v-date-picker>
            </v-menu>

            <v-menu ref="menu1" outlined v-model="menu1" :close-on-content-click="false"  transition="scale-transition"  offset-y max-width="290px"  min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="discountObj.endDate" label="Valid At" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="discountObj.endDate" no-title @input="menu1 = false" ></v-date-picker>
            </v-menu>

            <v-select label="Status" outlined :items="['active', 'disabled']" v-model="discountObj.status"  ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green-darken-1" @click="closeSaveDialog">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="saveDiscount" :loading="dialogLoading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Discount Dialog -->
    <v-dialog v-model="editDialog" persistent width="400">
      <v-card>
        <v-card-title class="text-h5"> Edit Discount Detail </v-card-title>
        <v-card-text style="padding-top: 24px !important">
          <v-form validate-on="submit lazy" @submit.prevent="submit">
            <v-text-field outlined label="Discount Code" small v-model="discountObj.code" ></v-text-field>
            <v-text-field type="number" outlined label="Discount Value" v-model="discountObj.disValue"  ></v-text-field>
            <v-select label="Discount Type" outlined :items="['fixed', 'percentage']" v-model="discountObj.disType"  ></v-select>
            <v-menu ref="menu1" outlined v-model="menu1" :close-on-content-click="false"  transition="scale-transition"  offset-y max-width="290px"  min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="discountObj.startDate" label="Start From" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="discountObj.startDate" no-title @input="menu1 = false" ></v-date-picker>
            </v-menu>

            <v-menu ref="menu1" outlined v-model="menu1" :close-on-content-click="false"  transition="scale-transition"  offset-y max-width="290px"  min-width="auto" >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="discountObj.endDate" label="Valid At" v-bind="attrs" @blur="date = parseDate(dateFormatted)" v-on="on" ></v-text-field>
              </template>
              <v-date-picker v-model="discountObj.endDate" no-title @input="menu1 = false" ></v-date-picker>
            </v-menu>
            <v-select label="Status" outlined :items="['active', 'disabled']" v-model="discountObj.status"  ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green-darken-1" @click="closeEditDialog">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="editDiscount" :loading="dialogLoading" >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Discount Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" persistent width="400">
      <v-card>
        <v-card-title> Do you want to delete ? </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green-darken-1" @click="closeDeleteDialog">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteDiscount"
            :loading="dialogLoading"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiPlusBoxMultipleOutline,
} from "@mdi/js";
import { ref, onMounted, reactive } from "@vue/composition-api";
import axios from "@axios";
import dayjs from "dayjs";
export default {
  setup() {
    const DISCOUNT_API_PATH = "/discount";
    const selectedDiscountId = ref("");
    const discountObj = reactive({
      code: "",
      disValue: 0,
      disType: "",
      status: "",
      startDate:"",
      endDate:""
    });

    const pricingObj = reactive({
      amount: 0,
      minHours: 0,
      maxHours: 0,
    });

    const discountDetails = ref();
    const dialogLoading = ref(false);
    const saveDialog = ref(false);
    const editDialog = ref(false);
    const deleteDialog = ref(false);

    // Helper Functions : START

    const formatDate = (date) => {
      return dayjs(date).format("DD MMM YYYY");
    }

    const startLoading = () => {
      dialogLoading.value = true;
    };

    const stopLoading = () => {
      dialogLoading.value = false;
    };

    // Helper Function : END




    // Dialog Functions : START

    const openSaveDialog = () => {
      saveDialog.value = true;
    };

    const openEditDialog = async (id) => {
      try {
        selectedDiscountId.value = id;
        const { data: discount } = await axios.get(`/discount/${selectedDiscountId.value}`
        );
        if (discount.data) {
          discountObj.code = discount.data.code,
          discountObj.disType = discount.data.is_fixed ? 'fixed' : 'percentage',
          discountObj.disValue = discount.data.is_fixed ? discount.data.amount_off : discount.data.percent_off,
          discountObj.status = discount.data.is_active ? 'active' : 'disabled',
          discountObj.startDate = dayjs(discount.data.start_at).format("YYYY-MM-DD") ,
          discountObj.endDate = dayjs(discount.data.end_at).format("YYYY-MM-DD")
        }
      } catch (error) {
        console.log(error); 
      } finally {
        editDialog.value = true;
      }
    };

    const openDeleteDialog = (id) => {
      deleteDialog.value = true;
      selectedDiscountId.value = id;
    };

    const closeSaveDialog = () => {
      saveDialog.value = false;
    };

    const closeEditDialog = () => {
      editDialog.value = false;
      selectedDiscountId.value = "";
      pricingObj.amount = 0;
      pricingObj.minHours = 0;
      pricingObj.maxHours = 0;
    };

    const closeDeleteDialog = () => {
      deleteDialog.value = false;
      selectedDiscountId.value = "";
    };

    // Dialog Functions : END




    // State Functions : START

    const setDiscountDetails = (data) => {
      discountDetails.value = data;
    };

    const addDiscountDetail = (data) => {
      discountDetails.value.push(data);
    };

    const updateDiscount = (data) => {
      console.log('updateDiscount:',data)
      const priceIndex = discountDetails.value.findIndex(
        (price) => price.id == data.id
      );
      if (priceIndex > -1) {
        discountDetails.value[priceIndex] = data;
      }
    };

    const deleteDiscountDetail = (priceId) => {
      const priceIndex = discountDetails.value.findIndex(
        (price) => price.id == priceId
      );
      if (priceIndex > -1) {
        discountDetails.value.splice(priceIndex, 1);
      }
    };

    // State Functions : END



    // Main Functions : START

    const getPricing = async () => {
      const { data: pricing } = await axios.get(DISCOUNT_API_PATH);
      setDiscountDetails(pricing.data);
      return;
    };

    const saveDiscount = async () => {
      try {

        startLoading();
        const obj = {
          code:discountObj.code.toUpperCase(),
          is_fixed: discountObj.disType == "fixed" ? true : false,
          amount_off: discountObj.disType == "fixed" ? parseFloat(discountObj.disValue) : 0,
          percent_off: discountObj.disType != "fixed" ? parseFloat(discountObj.disValue) : 0,
          is_active: discountObj.status == 'active' ? true : false,
          start_at: dayjs(discountObj.startDate).startOf('day').toISOString() ,
          valid_at: dayjs(discountObj.endDate).endOf('day').toISOString(),
        }
        const { data: discount } = await axios.post(DISCOUNT_API_PATH, obj);
        console.log('discount::',discount.data)
        addDiscountDetail(discount.data);
        discountObj.code =  "",
        discountObj.disValue = 0,
        discountObj.disType = "",
        discountObj.status = ""
        closeSaveDialog();
      } catch (error) {
        console.log('SaveError:',error)
        alert(error.response.data.message);
        throw error;
      } finally {
        stopLoading();
      }
    };

    const editDiscount = async () => {
      try {
        const obj = {
          code:discountObj.code.toUpperCase(),
          is_fixed: discountObj.disType == "fixed" ? true : false,
          amount_off: discountObj.disType == "fixed" ? parseFloat(discountObj.disValue) : 0,
          percent_off: discountObj.disType != "fixed" ? parseFloat(discountObj.disValue) : 0,
          is_active: discountObj.status == 'active' ? true : false,
          start_at: dayjs(discountObj.startDate).startOf('day').toISOString() ,
          valid_at: dayjs(discountObj.endDate).endOf('day').toISOString(),
        }
        startLoading();
        const { data: discount } = await axios.patch(`${DISCOUNT_API_PATH}/${selectedDiscountId.value}`,obj );
        updateDiscount(discount.data);
        discountObj.code =  "",
        discountObj.disValue = 0,
        discountObj.disType = "",
        discountObj.status = ""
        closeEditDialog();
      } catch (error) {
        console.log(error);
        alert(error.response.data.message);
        throw error;
      } finally {
        selectedDiscountId.value = "";
        stopLoading();
      }
    };

    const deleteDiscount = async () => {
      try {
        startLoading();
        await axios.delete(`${DISCOUNT_API_PATH}/${selectedDiscountId.value}`);
        deleteDiscountDetail(selectedDiscountId.value);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        selectedDiscountId.value = "";
        stopLoading();
        closeDeleteDialog();
      }
    };

    // Main Functions : END

    onMounted(() => getPricing());

    return {
      formatDate,
      discountObj,
      pricingObj,
      saveDiscount,
      saveDialog,
      openSaveDialog,
      closeSaveDialog,
      editDiscount,
      editDialog,
      openEditDialog,
      closeEditDialog,
      deleteDiscount,
      deleteDialog,
      openDeleteDialog,
      closeDeleteDialog,
      dialogLoading,
      startLoading,
      stopLoading,
      discountDetails,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlusBoxMultipleOutline,
      },
    };
  },
};
</script>

<style scoped>
.discount-card {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 0.75rem;
}
.coupon-code {
  font-size: 20px;
}
.tag_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: start;
}
.tag {
  color: rgb(0, 192, 0);
  /* background-color: rgba(0, 255, 0, 0.205);
  border: 1px solid rgba(0, 128, 0, 0.445);
  padding: 2px 12px;
  display: inline-block;
  border-radius: 20px; */
}
.tag.disable {
  color: #f00;
}
.inner-btn-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(10px);
}

.sub-title {
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 0.3rem;
}

.price {
  font-size: 3.5rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height:1;
}

.hours {
  font-size: 1rem;
  letter-spacing: 1.2px;
  /* text-transform: uppercase; */
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.card-footer {
  display: flex;
}

.card-footer .left,
.card-footer .right,
.card-body .left,
.card-body .right {
  width: 50%;
  text-align: center;
}

.right {
  display: flex;
  flex-direction: column;
}

.card-main-box {
  width: 100%;
  /* display: flex;
    flex-wrap: wrap;
    gap: 1rem; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.card-box {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  /* padding: 20px 30px 10px 30px !important; */
  border-radius: 8px;
  height: 140px;
  padding: 10px;
}

.add-price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  height: 170px;
  padding: 10px;
}

.box-title,
.card-heading,
.custom-title {
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

.custom-title {
  font-size: 2rem;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 576px) {
  .card-main-box {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media only screen and (max-width: 992px) {
  .card-main-box {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
